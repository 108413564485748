<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
      <CCard>
        <CCardBody>
            <h4>Locations</h4>
            <CButton color="primary" @click="addLocationModal = true">Add Location</CButton>
            <CAlert
              :show.sync="dismissCountDown"
              color="primary"
              fade
            >
              ({{dismissCountDown}}) {{ message }}
            </CAlert>
            <CDataTable
              hover
              :items="items"
              :fields="fields"
              :items-per-page="10"
              pagination
            >
              <template #edit="{item}">
                <td>
                  <CButton color="primary" @click="editLocationFunction( item )">Edit</CButton>
                </td>
              </template>
              <template #edit_styles="{item}">
                <td>
                  <CButton color="success" @click="editStyles( item.id )">Edit Styles</CButton>
                </td>
              </template>
            </CDataTable>
        </CCardBody>  
      </CCard>
      </transition>
    </CCol>

    <CModal
        title="Add Location"
        :show.sync="addLocationModal"
      >
      <CForm>
        <CInput
          :lazy="false"
          v-model="addLocation.customer_number"
          placeholder="Customer Number"
          autocomplete="given-name"
          invalidFeedback="This is a required field"
        />

        <CInput
          :lazy="false"
          v-model="addLocation.location_name"
          placeholder="Location Name"
          autocomplete="given-name"
          invalidFeedback="This is a required field"
        />
      </CForm>
      <template #footer>
        <CButton @click="addLocationModal = false, reset()" color="danger">Cancel</CButton>
        <CButton @click="saveLocation(addLocation)" color="success">Add</CButton>
      </template>
    </CModal>

    <CModal
        title="Edit Location"
        :show.sync="editModal"
      >
      <CForm v-if="editModal && editLocation">
        <CInput
          :lazy="false"
          v-model="editLocation.customer_number"
          placeholder="Customer Number"
          autocomplete="given-name"
          invalidFeedback="This is a required field"
        />

        <CInput
          :lazy="false"
          v-model="editLocation.location_name"
          placeholder="Location Name"
          autocomplete="given-name"
          invalidFeedback="This is a required field"
        />
      </CForm>
      <template #footer>
        <CButton @click="editModal = false, editLocation = null" color="danger">Cancel</CButton>
        <CButton @click="saveLocation(editLocation)" color="success">Save</CButton>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Roles',
  data: () => {
    return {
      items: [],
      fields: ['customer_number', 'location_name', 'edit', 'edit_styles'],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      you: null,
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      addLocation: {
        customer_number: '',
        location_name: ''
      },
      addLocationModal: false,
      editModal: false,
      editLocation: null
    }
  },
  computed: {
  },
  methods: {
    getRowCount (items) {
      return items.length
    },
    editStyles(id) {
      this.$router.push('/locations/' + id)
    },
    reset () {
      this.addLocation = {
        customer_number: '',
        location_name: ''
      }
    },
    roleLink (id) {
      return `roles/${id.toString()}`
    },
    saveLocation(val) {
      const postData = {
        location: val
      }
      axios.post(this.$apiAdress + '/api/locations/save?token=' + localStorage.getItem('api_token'), postData).then(response => {
        if (!val.id) {
          this.items.push(response)
        }
        this.reset()
        this.editLocation = null
        this.editModal = false
        this.addLocationModal = false
      })
    },
    editLink (id) {
      return `roles/${id.toString()}/edit`
    },
    showRole ( id ) {
      const roleLink = this.roleLink( id );
      this.$router.push({path: roleLink});
    },
    editLocationFunction ( val ) {
      this.editLocation = val
      this.editModal = true
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    getLocations (){
      let self = this;
      axios.get(   this.$apiAdress + '/api/locations/get?token=' + localStorage.getItem("api_token") )
      .then(function (response) {
        console.log(response)
        self.items = response.data;
      }).catch(function (error) {
        console.log(error);
        // self.$router.push({ path: '/login' });
      });
    }
  },
  mounted: function(){
    this.getLocations();
  }
}
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
